<template>
    <div class="frame">
      <div class="container">
        <h1 class="title">회원 상세보기</h1>
        <div class="info-box">
          <div class="info-center">
            <div class="left">
              <div class="input-box">
                <p>아이디<strong>*</strong></p>
                <input type="text" placeholder="ID를 입력해주세요" v-model="userInfo.id" maxlength="15" disabled>
              </div>
              <div class="input-box">
                <p>이름<strong>*</strong></p>
                <input type="text" placeholder="이름을 입력해주세요" v-model="userInfo.name" maxlength="7">
              </div>
              <!-- <div class="input-box">
                <p>드론 아이디<strong>*</strong></p>
                <input type="text" placeholder="드론 ID를 입력해주세요" v-model="userInfo.deviceId" maxlength="15">
              </div> -->
              <div class="input-box">
                <p>자격종류<strong>*</strong></p>
                <select v-model.number="userInfo.cert_type" min="1" max="3">
                  <option v-for="(label, value) in certTypeMapping" :key="value" :value="value">
                    {{ label }}
                  </option>                  
                </select>
              </div>
              <div class="input-box">
                <p>기수<strong>*</strong></p>
                <input type="text" v-model="userInfo.batch" disabled >
              </div>
              
            </div>
            <div class="right">
             
              <div class="input-box">
                <p>가입일 </p>
                <input type="text" v-model="userInfo.createdAt" maxlength="16" disabled>
              </div>

              <div class="input-box">
                <p>전화번호 </p>
                <input type="tel" placeholder="예) 010-1234-1234" v-model="userInfo.phone" maxlength="13">
              </div>
              <div class="input-box">
                <p>소속 </p>
                <input type="tel" placeholder="예) OO기업" v-model="userInfo.organization" maxlength="18">
              </div>
              <!-- <div class="input-box">
                <p>이수시간</p>
                <input type="number" v-model="userInfo.total_hours" min="0" disabled>
              </div>
              <div class="input-box">
                <p>진도율</p>
                <input type="number" v-model="userInfo.progress" min="0" max="100" disabled>
              </div> -->
              <div class="input-box">
                <p>승인여부</p>
                <div class="checkbox">
                  <mdb-input type="radio" id="service-1" name="groupOfMaterialRadios" radioValue="true" v-model="isChecked" label="서비스 승인"/>
                  <mdb-input type="radio" id="service-2" name="groupOfMaterialRadios" radioValue="false" v-model="isChecked" label="서비스 해제"/>
                </div>
              </div>
            </div>
          </div>
          <div class="line"/>
          <div class="info-footer">
            <button class="custom-btn-box btn-back" @click="backButton">목록</button>
            <button class="custom-btn-box btn-delete" @click="showAlert">삭제</button>
            <button class="custom-btn-box btn-edit" @click="editButton">수정</button>
          </div>
        </div>
      </div>
    </div>
  </template>
  
  <script>
  import {mdbInput} from "mdbvue";
  import axios from "@/axios";
  import {getDateTime} from "@/lib/prettyDate";
  import {sendWorkLog} from "@/lib/logModule";
  
  export default {
    name: "NonUserInfo",
    components: {
      mdbInput
    },
    data() {
      return {
        token: this.$store.state.token.access_token,
        id: this.$route.params.id,
        userInfo: {
          createdAt: '',
          //deviceId: '',
          //droneId: '',
          id: '',
          name: '',
          organization: '',
          phone: '',
          provider: '',
          role: '',
          status: false,
          updatedAt: '',
          uuid: '',
          cert_type:1,
          batch:'',
          total_hours:0,
          progress:0,
        },
        isChecked: 'false',
        certTypeMapping: {
          1: "1종",
          2: "2종",
          3: "3종",
        },
      }
    },
    mounted() {
      this.init()
    },
    methods: {
      init() {
        this.getUserInfo()
      },
      getUserInfo() {
        const self = this;
  
        const config = {
          method: 'get',
          url: `/users/${self.id}`,
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${self.token}`
          },
        };
        axios(config)
            .then((res) => {
              self.userInfo = res.data
  
              // self.userInfo.drones.forEach((drone) => {
              //   self.userInfo.deviceId = drone.deviceId
              //   self.userInfo.droneId = drone.uuid
              // })
  
              self.userInfo.createdAt = getDateTime(new Date(self.userInfo.createdAt))
              self.userInfo.status = res.data.status;
              self.isChecked = res.data.status.toString();
             
              sendWorkLog('050101');
            })
            .catch((error) => {
              console.error("Error fetching user info:", error);
            });
           
      },
      editButton() {
        const self = this;
  
        // if (self.userInfo.droneId.trim().length === 0) {
        //   self.$swal.fire({title: '입력 필수', text: '드론 아이디는 필수 입니다.', icon: 'info'})
        //   return
        // }
        if (self.userInfo.name.trim().length === 0) {
          self.$swal.fire({title: '입력 필수', text: '이름은 필수 입니다.', icon: 'info'})
          return
        }
  
        self.userInfo.status = self.isChecked !== 'false';
  
        const data = JSON.stringify({
          "name": self.userInfo.name,
          "status": self.userInfo.status,
          "phone": self.userInfo.phone,
          "organization": self.userInfo.organization,
          // "droneId": self.userInfo.droneId,
          // "deviceId": self.userInfo.deviceId,
          "cert_type": self.userInfo.cert_type,
          "batch": self.userInfo.batch,

        });
  
        const config = {
          method: 'put',
          url: `/users/${self.id}`,
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${self.token}`
          },
          data: data
        };
  
        axios(config)
            .then((res) => {
              console.log(res)
              self.$swal
                  .fire({title: '수정 완료', text: '회원이 수정 되었습니다.', icon: 'success'})
                  .then(() => {
                    sendWorkLog('050102');
                    self.$router.replace({name: 'userList'})
                  })
            })
      },
  
      showAlert() {
        const self = this;
        self.$swal.fire({
          title: '삭제하시겠습니까?',
          text: "삭제 시, 복구가 불가능합니다",
          icon: 'warning',
          showCancelButton: true,
          confirmButtonText: '삭제',
          cancelButtonText: '취소'
        }).then((result) => {
          if (result.isConfirmed) {
            self.deleteButton()
          }
        })
      },
  
      deleteButton() {
        const self = this;
  
        const config = {
          method: 'put',
          url: `/users/delete?id=${self.id}`,
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${self.token}`
          },
        };
        
        axios(config)
            .then((res) => {
              console.log(res)
              self.$swal
                  .fire({title: '삭제 완료', text: '회원이 삭제 되었습니다.', icon: 'success'})
                  .then(() => {
                    sendWorkLog('050303');
                    self.$router.replace({name: 'userList'})
                  })
            })
      },
      backButton() {
          this.$router.replace({name: 'userList'})
      }
    }
  }
  </script>
  
  <style scoped>
  
  .frame {
    padding: 40px;
  }
  
  .container {
    margin: 0 auto;
    padding: 0;
    display: flex;
    flex-direction: column;
  }
  
  .title {
    /*font-family: 'Roboto',serif;*/
    font-family: 'NotoSansKR', serif;
    font-weight: 600;
    font-size: 24px;
    line-height: 140%;
    margin-bottom: 20px;
  }
  
  .info-box {
    display: flex;
    flex-direction: column;
    border-radius: 8px;
    background: #FFFFFF;
    /*font-family: 'Roboto',serif;*/
    font-family: 'NotoSansKR', serif;
    font-size: 14px;
  }
  
  /* header */
  
  .line {
    background: #E6E7E9;
    height: 1px;
  }
  
  /* center */
  .info-center {
    display: flex;
  }
  
  .left, .right {
    padding: 40px;
    width: 527px;
  }
  
  .left .input-box p {
    font-weight: 600;
    font-size: 14px;
    line-height: 140%;
    margin-bottom: 10px;
  }
  
  .right .input-box p {
    font-weight: 600;
    font-size: 14px;
    line-height: 140%;
    margin-bottom: 10px;
  }
  
  .left .input-box strong {
    color: #BE123C;
  }
  
  .right .input-box strong {
    color: #BE123C;
  }
  
  input {
    height: 40px;
    width: 100%;
    margin-bottom: 20px;
    padding: 10px 16px 10px;
    outline: none;
  }
  select {
    height: 40px;
    width: 100%;
    margin-bottom: 20px;
    padding: 10px 16px 10px;
    outline: none;
    font-size: 14px; /* 글자 크기 조정 */
    border: 1px solid #6e6d6d; /* 테두리 */
    border-radius: 4px; /* 모서리 둥글게 */
    background-color: #fff; /* 배경색 */
  }
  
  /* footer */
  .info-footer {
    background: #FFFFFF;
    border-radius: 0 0 8px 8px;
    height: 73px;
    padding: 17px 16px 16px;
    margin-left: auto;
  }
  
  .info-footer .btn-edit {
    margin: 0;
    padding: 11px 12px;
    font-weight: 500;
    font-size: 14px;
    line-height: 140%;
    border: 0;
    width: 67px;
  }
  
  .info-footer .btn-delete {
    background-color: #ff4b4b;
    color: white;
    margin: 0 15px 0 0;
    padding: 11px 12px;
    font-weight: 500;
    font-size: 14px;
    line-height: 140%;
    border: 0;
    width: 67px;
  }
  
  .info-footer .btn-back {
      background-color: #ff9800; /* 버튼의 배경색 */
      color: white;
      margin: 0 15px 0 0;
      padding: 11px 12px;
      font-weight: 500;
      font-size: 14px;
      line-height: 140%;
      border: 0;
      width: 67px;
  }
  </style>